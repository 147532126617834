@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    display: none;
  } */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

details summary::-webkit-details-marker {
  display: none;
  color: #efcb76;
}

input[type="file"]::placeholder {
  color: transparent;
}

.car-list {
  background: linear-gradient(
    180deg,
    rgba(251, 245, 236, 0) -56.41%,
    #fbf5ec 70.88%
  );
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Custom styles for the login modal */
.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.input-field:focus {
  outline: none;
  border-color: #007bff;
}

.custom-button {
  width: 100%;
  background-color: #0e1427;
  color: white;
  padding: 0.75rem;
  text-align: center;
  border-radius: 9999px;
  transition: background-color 0.3s;
}

.custom-button:hover {
  background-color: #0e1427;
}

.remember-me {
  display: flex;
  align-items: center;
}

.remember-me input {
  margin-right: 0.5rem;
}

.link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.social-login-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.social-login-button {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  color: white;
  cursor: pointer;
}

.social-login-button.facebook {
  background-color: #3b5998;
}

.social-login-button.google {
  background-color: #db4437;
}

.social-login-button.apple {
  background-color: #000;
}

.social-login-button .icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.divider:not(:empty)::before {
  margin-right: 0.5em;
}

.divider:not(:empty)::after {
  margin-left: 0.5em;
}

.signup-link {
  color: #007bff;
  cursor: pointer;
}

.custom-dialog-container {
  border-radius: 20px;
}

/* Custom styles for the signup modal */
.signup-dialog-title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 1.25rem;
  font-weight: bold;
}

.close-button {
  position: absolute;
  right: 16px;
  top: 16px;
  color: gray;
}

.signup-dialog-content {
  padding: 1.5rem 2rem;
}

.input-group {
  margin-bottom: 1rem;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.input-field:focus {
  outline: none;
  border-color: #007bff;
}

.terms-text {
  text-align: center;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.terms-text a {
  color: #007bff;
  text-decoration: underline;
}

.signup-dialog-actions {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.signup-button {
  width: 100%;
  background-color: #000;
  color: white;
  padding: 0.75rem;
  text-align: center;
  border-radius: 9999px;
  transition: background-color 0.3s;
}

.signup-button:hover {
  background-color: #333;
}

.login-link {
  text-align: center;
  margin-top: 1rem;
}

.login-link-text {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

@layer utilities {
  .support-item {
    @apply flex justify-start items-center w-full gap-6 max-md:border max-md:rounded-full max-md:py-2 max-md:px-4 max-md:bg-[#E8F7FC];
  }
}

.custom-input {
  @apply caret-transparent;
  caret-color: transparent;
  /* user-select: none; */
}
